var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"ContentHeader"},[_c('div',{staticClass:"HeaderAll"},[_vm._t("titlebreadcrumb"),_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),_vm._l((_vm.breadcrumb),function(item,i){return _c('a-breadcrumb-item',{key:i},[(i + 1 < _vm.breadcrumb.length)?_c('router-link',{attrs:{"to":{ name: item.nameroute }}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])],1)})],2)],2),_c('div',{staticClass:"ContentHeaderSearch"},[_c('a-input-search',{staticStyle:{"width":"327px"},attrs:{"placeholder":"Search Here","allowClear":"","default-value":_vm.$route.query.search != undefined ? _vm.$route.query.search : ''},on:{"search":_vm.onSearch}}),_c('a-button',{ref:"buttonadvanced",attrs:{"type":"advancedsearch"},on:{"click":_vm.advanced}},[_c('a-badge',{attrs:{"dot":_vm.dotAdvancedSearch}},[_vm._v("Advanced Search "),_c('a-icon',{attrs:{"type":"filter"}})],1)],1),_c('div',{staticClass:"advancedsearch-dropdown",style:(_vm.advancedsearch ? 'display: block;' : 'display: none;'),on:{"click":_vm.closeadvance}},[_c('div',{staticClass:"_dropdownwraper",style:({ top: _vm.top + 'px' })},[_c('div',{staticClass:"button-right-top"},[_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.advanced}},[_c('span',{staticClass:"ant-modal-close-x"},[_c('a-icon',{attrs:{"type":"close"}})],1)])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.Submit}},[_c('a-row',{attrs:{"gutter":[15, 15]}},[_c('a-col',{attrs:{"lg":6}},[_c('a-form-item',{attrs:{"label":"Nama Group"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'group',
                        {
                          initialValue:
                            _vm.$route.query.group != undefined
                              ? _vm.$route.query.group
                              : null,
                        },
                      ]),expression:"[\n                        'group',\n                        {\n                          initialValue:\n                            $route.query.group != undefined\n                              ? $route.query.group\n                              : null,\n                        },\n                      ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1),_c('a-col',{attrs:{"lg":6}},[_c('a-form-item',{attrs:{"label":"Periode Awal"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'periode_awal',
                        {
                          initialValue:
                            _vm.$route.query.periode_awal != undefined
                              ? _vm.$route.query.periode_awal
                              : null,
                        },
                      ]),expression:"[\n                        'periode_awal',\n                        {\n                          initialValue:\n                            $route.query.periode_awal != undefined\n                              ? $route.query.periode_awal\n                              : null,\n                        },\n                      ]"}],staticStyle:{"width":"100%"},attrs:{"autocomplete":"off","format":"DD MMM YYYY"}})],1)],1),_c('a-col',{attrs:{"lg":6}},[_c('a-form-item',{attrs:{"label":"Periode Akhir"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'periode_akhir',
                        {
                          initialValue:
                            _vm.$route.query.periode_akhir != undefined
                              ? _vm.$route.query.periode_akhir
                              : null,
                        },
                      ]),expression:"[\n                        'periode_akhir',\n                        {\n                          initialValue:\n                            $route.query.periode_akhir != undefined\n                              ? $route.query.periode_akhir\n                              : null,\n                        },\n                      ]"}],staticStyle:{"width":"100%"},attrs:{"autocomplete":"off","format":"DD MMM YYYY"}})],1)],1),_c('a-col',{attrs:{"lg":6}},[_c('a-form-item',{attrs:{"label":"Asuransi"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'asuransi',
                        {
                          initialValue:
                            _vm.$route.query.asuransi != undefined
                              ? _vm.$route.query.asuransi
                              : null,
                        },
                      ]),expression:"[\n                        'asuransi',\n                        {\n                          initialValue:\n                            $route.query.asuransi != undefined\n                              ? $route.query.asuransi\n                              : null,\n                        },\n                      ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1),_c('a-col',{attrs:{"lg":6}},[_c('a-form-item',{attrs:{"label":"Jenis Asuransi"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'jenis_asuransi',
                        {
                          initialValue:
                            _vm.$route.query.jenis_asuransi != undefined
                              ? _vm.$route.query.jenis_asuransi
                              : null,
                        },
                      ]),expression:"[\n                        'jenis_asuransi',\n                        {\n                          initialValue:\n                            $route.query.jenis_asuransi != undefined\n                              ? $route.query.jenis_asuransi\n                              : null,\n                        },\n                      ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1),_c('a-col',{attrs:{"lg":24}},[_c('div',{staticClass:"button-right"},[_c('a-button',{attrs:{"type":"default"},on:{"click":_vm.Reset}},[_vm._v("Reset")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Cari")])],1)])],1)],1)],1)])],1)])]),_c('a-table',{attrs:{"rowKey":(record) => record.id,"columns":_vm.columns,"dataSource":_vm.data,"pagination":false,"loading":_vm.loading,"scroll":{ x: 1200, y: _vm.heighttable }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({ column }){return _c('div',{},[_c('div',{staticClass:"wrapbuttonsort"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handlesTbl('periode_awal_format', [
              undefined,
              'ascend',
              'descend',
            ])}}},[_c('div',{staticClass:"customsortdown"},[_c('div',{style:(column.choseSort == 'periode_awal_format'
                  ? 'color:#0060D1'
                  : 'color:#77849D')},[_vm._v(" Periode Awal ")])])])],1),_c('div',[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handlesTbl('periode_akhir_format', [
              undefined,
              'ascend',
              'descend',
            ])}}},[_c('div',{staticClass:"customsortdown"},[_c('div',{style:(column.choseSort == 'periode_akhir_format'
                  ? 'color:#0060D1'
                  : 'color:#77849D')},[_vm._v(" Periode Akhir ")])])])],1)])}},{key:"filterIcon",fn:function(filtered, column){return _c('div',{staticClass:"customSorter"},[_c('a-icon',{style:({
          color: column.customSort == 'ascend' ? '#0060D1' : '#77849D',
        }),attrs:{"type":"caret-up"}}),_c('a-icon',{style:({
          color: column.customSort == 'descend' ? '#0060D1' : '#77849D',
        }),attrs:{"type":"caret-down"}})],1)}},{key:"nama_group",fn:function(text, record){return [_c('router-link',{attrs:{"to":{ name: 'ListDaftarPT', params: { id: record.id } }}},[_vm._v(" "+_vm._s(text)+" "),_c('a-icon',{attrs:{"type":"right"}})],1)]}},{key:"periode",fn:function(text, record){return [_c('div',{staticClass:"iconcalendar",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('@/assets/img/icon/periode_awal.svg')}}),_vm._v(" "+_vm._s(record.periode_awal)+" ")]),_c('div',{staticClass:"iconcalendar"},[_c('img',{attrs:{"src":require('@/assets/img/icon/periode_akhir.svg')}}),_vm._v(" "+_vm._s(record.periode_akhir)+" ")])]}},{key:"jenis_asuransi",fn:function(text){return [(text == 'Health')?_c('div',{staticClass:"iconhealth"},[_c('img',{attrs:{"src":require('@/assets/img/icon/health.svg')}}),_vm._v(" "+_vm._s(text)+" ")]):_vm._e(),(text == 'Life')?_c('div',{staticClass:"iconhealth iconlife"},[_c('img',{attrs:{"src":require('@/assets/img/icon/life.svg')}}),_vm._v(_vm._s(text.toUpperCase())+" ")]):_vm._e(),(text == 'Ih')?_c('div',{staticClass:"iconhealth iconih"},[_c('img',{attrs:{"src":require('@/assets/img/icon/ih.svg')}}),_vm._v(_vm._s(text.toUpperCase())+" ")]):_vm._e()]}},{key:"total_peserta",fn:function(text){return [_c('div',{staticClass:"iconpeserta"},[_c('img',{attrs:{"src":require('@/assets/img/icon/peserta.svg')}}),_vm._v(" "+_vm._s(text)+" ")])]}},{key:"action",fn:function(text, record){return [_c('a-button',{attrs:{"type":"download"},on:{"click":(event) =>
            _vm.$router.push({ name: 'ListDaftarPT', params: { id: record.id } })}},[_vm._v("View "),_c('img',{attrs:{"src":require('@/assets/img/icon/eye.svg')}})])]}}])},[_c('template',{slot:"footer"},[_vm._v(" "+_vm._s(null)+" ")])],2),_c('div',{staticClass:"paginationcustom",staticStyle:{"margin-top":"0px !important"}},[_c('a-pagination',{attrs:{"showSizeChanger":"","pageSizeOptions":_vm.pageSizeOption,"pageSize":_vm.pageSize,"total":_vm.total,"showTotal":(total, range) =>
          ` ${range[0]}-${range[1]}  of ${
            _vm.pagination.total == undefined ? '0' : _vm.pagination.total
          } `},on:{"showSizeChange":_vm.onShowSizeChange,"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"change":_vm.handlePageChange},scopedSlots:_vm._u([{key:"buildOptionText",fn:function(props){return [(props.value !== '1000000')?_c('span',[_vm._v(" "+_vm._s(props.value)+" / page ")]):_vm._e(),(props.value == '1000000')?_c('span',[_vm._v("All")]):_vm._e()]}}]),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }